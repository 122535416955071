import { JSONable } from '@frontend-composer/core';
import { Column } from '../modules/GenericList/types';
import { GenericListModuleData } from 'modules/GenericList/types';
import {
  OFFERS_BULK_PUBLISH,
  OFFERS_FETCH_LIST,
  OFFERS_TOGGLE_SELECTED_ROW,
  OFFERS_UNSELECT_ROW,
  OFFERS_UPLOAD_FILE,
} from '@/eventrixEvents/offers';

const CHILDREN_SELECTOR = '& > *';

const requestsListModuleData: JSONable = {
  title: 'offers',
  baseStateName: 'offers',
  events: {
    loadList: OFFERS_FETCH_LIST,
    onRowClick: OFFERS_TOGGLE_SELECTED_ROW,
    bulkPublish: OFFERS_BULK_PUBLISH,
    onUnmount: OFFERS_UNSELECT_ROW,
    upload: OFFERS_UPLOAD_FILE,
  },
  translationContext: 'offers',
  hasActiveDropzone: true,
  dropzoneData: {
    icon: 'bytesize:upload',
    title: 'orders.dropzone.title',
    description: 'orders.dropzone.description',
  },
  hasSelection: true,
  filtersModule: 'offersFilters',
  limit: 20,
  headerActions: [],
  listColumns: [
    {
      id: 'client',
      title: 'client',
      key: 'client',
      component: 'client',
      sx: {
        maxWidth: {
          xl: 232,
          xs: 182,
        },
        minWidth: {
          xl: 232,
          xs: 182,
        },
        // padding: '16px 0',
        [CHILDREN_SELECTOR]: {
          maxWidth: {
            xl: 200,
            xs: 150,
          },
          minWidth: {
            xl: 130,
            xs: 100,
          },
        },
      },
    },
    {
      id: 'loading',
      title: 'loadingPlace',
      key: 'firstLoadingPlace',
      component: 'orderPlace',
      sx: {
        maxWidth: {
          xl: 192,
          xs: 162,
        },
        minWidth: {
          xl: 192,
          xs: 162,
        },
      },
    },
    {
      id: 'unloading',
      title: 'unloadingPlace',
      key: 'lastUnloadingPlace',
      component: 'orderPlace',
      sx: {
        maxWidth: {
          xl: 192,
          xs: 162,
        },
        minWidth: {
          xl: 192,
          xs: 162,
        },
      },
    },
    {
      id: 'elapsedTime',
      title: 'elapsedTime',
      key: 'elapsedTime',
      component: 'requestsElapsedTime',
      minVisibleWidth: 1500,
      sx: {
        maxWidth: {
          xl: 167,
          xs: 132,
        },
        minWidth: {
          xl: 167,
          xs: 132,
        },
        [CHILDREN_SELECTOR]: {
          maxWidth: {
            xl: 135,
            xs: 100,
          },
        },
      },
    },
    {
      id: 'source',
      title: 'source',
      key: 'source',
      align: 'center',
      component: 'requestsSource',
      sx: {
        maxWidth: 112,
        minWidth: 112,
      },
    },
    {
      id: 'responsiblePerson',
      title: 'responsiblePerson',
      key: 'responsiblePerson',
      component: 'offerResponsiblePerson',
      sx: {
        maxWidth: {
          xl: 262,
          xs: 232,
        },
        minWidth: {
          xl: 262,
          xs: 232,
        },
        [CHILDREN_SELECTOR]: {
          maxWidth: {
            xl: 230,
            xs: 200,
          },
          minWidth: {
            xl: 150,
            xs: 120,
          },
          width: '100%',
        },
      },
    },
    {
      id: 'publicationStatus',
      title: '',
      key: 'publicationStatus',
      component: 'orderPublicationStatus',
      sx: {
        maxWidth: 107,
        minWidth: 107,
        [CHILDREN_SELECTOR]: {
          minWidth: 75,
          width: 75,
        },
      },
    },
    {
      id: 'customStatus',
      title: 'status',
      key: 'customStatus',
      component: 'offerStatus',
      align: 'right',
      sx: {
        width: '100%',
        maxWidth: 222,
        minWidth: 222,
        [CHILDREN_SELECTOR]: {
          maxWidth: 190,
          minWidth: 190,
          ml: 'auto',
        },
      },
    },
  ] satisfies Column[],
  actionsColumnWidth: 106,
  listItemActions: [],
  listMainActions: [],
  mergeActionsToDropdown: true,
  showCursorPointer: true,
  tablePaddingBottom: 276,
} satisfies GenericListModuleData;

export default requestsListModuleData;
