export const TMS_FIRETMS_INTEGRATE_OPEN_MODAL = 'integrateTms.firetms.open.modal';
export const TMS_FIRETMS_INTEGRATE = 'integrateTms.firetms';
export const TMS_FIRETMS_SUCCESS = 'integrateTms.firetms.success';
export const TMS_FIRETMS_REMOVE = 'removeTms.firetms';

export const TMS_UNITERPRO_INTEGRATE_OPEN_MODAL = 'integrateTms.uniterpro.open.modal';
export const TMS_UNITERPRO_INTEGRATE = 'integrateTms.uniterpro';
export const TMS_UNITERPRO_SUCCESS = 'integrateTms.uniterpro.success';
export const TMS_UNITERPRO_REMOVE = 'removeTms.uniterpro';

export const TMS_FETCH_LIST = 'Tms.fetch.list';
export const TMS_FETCH_SETTINGS = 'Tms.fetch.settings';
