import { OrderStatus } from '../types/orders';
import { JSONable } from '@frontend-composer/core';
import {
  ORDERS_ARCHIVE,
  ORDERS_BULK_ARCHIVE,
  ORDERS_BULK_PUBLISH,
  ORDERS_COPY,
  ORDERS_EXPORT_SELECTED_TO_CSV,
  ORDERS_FETCH_LIST,
  ORDERS_OPEN_FORM,
  ORDERS_OPEN_PUBLISH_FORM,
  ORDERS_REMOVE,
  ORDERS_REMOVE_DRAFT,
  ORDERS_SEND_TO_TMS,
  ORDERS_SET_PAGE,
  ORDERS_SUBSCRIBE_ON_LIST,
  ORDERS_TOGGLE_SELECTED_ROW,
  ORDERS_UNSELECT_ROW,
  ORDERS_UPLOAD_FILE,
} from '@/eventrixEvents/orders';
import { AI_EFFECTIVITY_OPEN_DETAILS } from '../eventrixEvents/ai';
import { Column } from '../modules/GenericList/types';
import { GenericListModuleData } from 'modules/GenericList/types';
import { TmsName } from '@/types/tms';

const CHILDREN_SELECTOR = '& > *';

const ordersListModuleData: JSONable = {
  title: 'orders',
  baseStateName: 'orders',
  events: {
    loadList: ORDERS_FETCH_LIST,
    setPage: ORDERS_SET_PAGE,
    onMount: ORDERS_SUBSCRIBE_ON_LIST,
    onRowClick: ORDERS_TOGGLE_SELECTED_ROW,
    onUnmount: ORDERS_UNSELECT_ROW,
    exportToCsv: ORDERS_EXPORT_SELECTED_TO_CSV,
    upload: ORDERS_UPLOAD_FILE,
    bulkPublish: ORDERS_BULK_PUBLISH,
    bulkArchive: ORDERS_BULK_ARCHIVE,
  },
  idKey: 'uuid',
  translationContext: 'orders',
  filtersModule: 'ordersFilters',
  hasActiveDropzone: true,
  hasSelection: true,
  dropzoneData: {
    icon: 'bytesize:upload',
    title: 'orders.dropzone.title',
    description: 'orders.dropzone.description',
  },
  limit: 20,
  headerActions: [],
  listColumns: [
    {
      id: 'client',
      title: 'client',
      key: 'client',
      component: 'client',
      sx: {
        maxWidth: {
          xl: 212,
          xs: 162,
        },
        minWidth: {
          xl: 212,
          xs: 162,
        },
        padding: '16px 0',
        [CHILDREN_SELECTOR]: {
          maxWidth: {
            xl: 200,
            xs: 150,
          },
          minWidth: {
            xl: 130,
            xs: 100,
          },
        },
      },
    },
    {
      id: 'loading',
      title: 'loadingPlace',
      key: 'firstLoadingPlace',
      component: 'orderPlace',
      sx: {
        maxWidth: {
          xl: 182,
          xs: 152,
        },
        minWidth: {
          xl: 182,
          xs: 152,
        },
      },
    },
    {
      id: 'unloading',
      title: 'unloadingPlace',
      key: 'lastUnloadingPlace',
      component: 'orderPlace',
      sx: {
        maxWidth: {
          xl: 182,
          xs: 152,
        },
        minWidth: {
          xl: 182,
          xs: 152,
        },
      },
    },
    {
      id: 'requirements',
      title: 'requirements',
      key: 'requirements',
      component: 'orderRequirements',
      minVisibleWidth: 1500,
      sx: {
        maxWidth: {
          xl: 167,
          xs: 132,
        },
        minWidth: {
          xl: 167,
          xs: 132,
        },
        [CHILDREN_SELECTOR]: {
          maxWidth: {
            xl: 135,
            xs: 100,
          },
        },
      },
    },
    {
      id: 'createdAt',
      title: 'createdAt',
      key: 'createdAt',
      component: 'dayjsDate',
      minVisibleWidth: 1800,
      sx: {
        maxWidth: 112,
        minWidth: 112,
      },
    },
    {
      id: 'price',
      title: 'price',
      key: 'paymentConditions',
      component: 'orderPrice',
      sx: {
        minWidth: 92,
        maxWidth: {
          xl: 132,
          xs: 92,
        },
      },
    },
    {
      id: 'responsiblePerson',
      title: 'responsiblePerson',
      key: 'responsiblePerson',
      component: 'orderResponsiblePerson',
      sx: {
        maxWidth: {
          xl: 262,
          xs: 232,
        },
        minWidth: {
          xl: 262,
          xs: 232,
        },
        [CHILDREN_SELECTOR]: {
          maxWidth: {
            xl: 230,
            xs: 200,
          },
          minWidth: {
            xl: 150,
            xs: 120,
          },
          width: '100%',
        },
      },
    },
    {
      id: 'publicationStatus',
      title: '',
      key: 'publicationStatus',
      component: 'orderPublicationStatus',
      sx: {
        maxWidth: 107,
        minWidth: 107,
        [CHILDREN_SELECTOR]: {
          minWidth: 75,
          width: 75,
        },
      },
    },
    {
      id: 'customStatus',
      title: 'status',
      key: 'customStatus',
      component: 'orderStatus',
      align: 'right',
      sx: {
        width: '100%',
        maxWidth: 190,
        minWidth: 190,
        [CHILDREN_SELECTOR]: {
          maxWidth: 160,
          minWidth: 160,
          ml: 'auto',
        },
      },
    },
  ] satisfies Column[],
  actionsColumnWidth: 106,
  listItemActions: [
    {
      iconName: 'ph:open-ai-logo-fill',
      title: 'effectivityAI',
      eventName: AI_EFFECTIVITY_OPEN_DETAILS,
      auth: {
        'featureFlags.effectivityAI': {
          $eq: true,
        },
      },
    },
    {
      iconName: 'fluent:send-16-regular',
      title: 'sendToTMS',
      eventName: ORDERS_SEND_TO_TMS,
      auth: {
        'tmsSettings.type': {
          $in: Object.values(TmsName),
        },
      },
      when: {
        isAiProcessing: {
          $ne: true,
        },
      },
    },
    {
      iconName: 'mdi:send-circle-outline',
      title: 'publish',
      eventName: ORDERS_OPEN_PUBLISH_FORM,
      when: {
        status: {
          $nin: [OrderStatus.IN_PROGRESS, OrderStatus.DRAFT, OrderStatus.ERROR],
        },
        isAiProcessing: {
          $ne: true,
        },
      },
    },
    {
      iconName: 'carbon:copy',
      title: 'copy',
      eventName: ORDERS_COPY,
      when: {
        isAiProcessing: {
          $ne: true,
        },
      },
    },
    {
      iconName: 'mingcute:pencil-2-line',
      title: 'edit',
      eventName: ORDERS_OPEN_FORM,
      when: {
        isAiProcessing: {
          $ne: true,
        },
      },
    },
    {
      iconName: 'ph:archive',
      title: 'archive',
      eventName: ORDERS_ARCHIVE,
      confirm: {
        title: 'archiveOrder',
        content: 'archiveOrderDescription',
      },
      when: {
        status: {
          $nin: [OrderStatus.DRAFT, OrderStatus.ERROR],
        },
        'customStatus.name': {
          $nin: ['Archived'],
        },
      },
    },
    {
      iconName: 'ph:trash',
      title: 'remove',
      eventName: ORDERS_REMOVE,
      confirm: {
        title: 'removeOrder',
        content: 'removeOrderDescription',
      },
      when: {
        status: {
          $nin: [OrderStatus.DRAFT, OrderStatus.ERROR],
        },
      },
    },
    {
      iconName: 'ph:trash',
      title: 'removeDraft',
      eventName: ORDERS_REMOVE_DRAFT,
      confirm: {
        title: 'removeOrder',
        content: 'removeOrderDescription',
      },
      when: {
        status: {
          $in: [OrderStatus.IN_PROGRESS, OrderStatus.DRAFT, OrderStatus.ERROR],
        },
      },
    },
  ],
  listMainActions: [
    {
      iconName: 'mingcute:pencil-2-line',
      title: 'edit',
      eventName: ORDERS_OPEN_FORM,
      when: {
        defaultAction: {
          $eq: 'EDIT',
        },
        isAiProcessing: {
          $ne: true,
        },
      },
    },
    {
      iconName: 'mdi:send-circle-outline',
      title: 'publish',
      eventName: ORDERS_OPEN_PUBLISH_FORM,
      when: {
        defaultAction: {
          $eq: 'PUBLISH',
        },
        isAiProcessing: {
          $ne: true,
        },
      },
    },
    {
      iconName: 'fluent:send-16-regular',
      title: 'sendToTMS',
      eventName: ORDERS_SEND_TO_TMS,
      auth: {
        'tmsSettings.type': {
          $in: Object.values(TmsName),
        },
      },
      when: {
        defaultAction: {
          $eq: 'SEND_TO_TMS',
        },
        isAiProcessing: {
          $ne: true,
        },
      },
    },
  ],
  mergeActionsToDropdown: true,
  showCursorPointer: true,
  tablePaddingBottom: 280,
} satisfies GenericListModuleData;

export default ordersListModuleData;
